import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FCFBF7',
      neutralLight3: '#F5F3EB',
      neutralLight2: '#EAE9E0',
      neutralLight1: '#C1BCA9',
      neutralDark4: '#8E9192',
      neutralDark3: '#4F565C',
      neutralDark2: '#353C41',
      neutralDark1: '#000000',
      primaryLight: '#B23939',
    },
  },
  fontFamily: {
    heading: "'Shippori Mincho', serif",
    paragraph: "'Hanken Grotesk', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
